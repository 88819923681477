<template>
  <div>
    <security></security>
  </div>
</template>


<script>
import Security from './../../components/account/security';
export default {
  data: () => ({}),
  metaInfo() {
    return {
      title: this.$i18n.t('account.security.title'),
    };
  },
  components: {
    Security
  }
}
</script>